<!--
 * @Description: 关于我们页面组件，未完成
 -->
<template>
  <div class="login" >
    <div class="log-box">
      <div class="tog-log">
        <!-- <div class="log-tit " :class="loginType == 1 ? 'active' : ''" @click="togLoginType(1)">验证码登录</div> -->
        <div class="log-tit" :class="loginType == 2 ? 'active' : ''" style="margin-left: 30px;" @click="togLoginType(2)">密码登录</div>
      </div>
      <el-divider></el-divider>
      <el-form :model="LoginUser" :rules="rules" ref="ruleForm" class="loginform" label-width="80px">
        <el-form-item prop="account" >
          <el-input  v-model="LoginUser.account" placeholder="请输入邮箱或手机号"></el-input>
        </el-form-item>
        <el-form-item prop="password" v-show="loginType == 2">
          <el-input type="password" show-password v-model="LoginUser.password" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item  v-show="loginType == 1">
          <el-input v-model="LoginUser.code" placeholder="请输入验证码">
            <template slot="append">
              <div class="code">获取验证码</div>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <div class="xiyi" >
        <el-checkbox v-model="checked">请勾选同意 <span style="color: #CE3720;">《先谱特电商用户注册协议和隐私政策》</span></el-checkbox>
      </div>
      <div class="btn" @click="Login">登录</div>
      <el-divider></el-divider>
      <div class="btn-box-b">
        <div class="qita" @click="jumpall">忘记密码</div>
        <div class="reg-btn" @click="jumpReg">新用户注册</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { loginApi } from "../../api/user.js";
export default {
  components: {
  },
  data(){
    // 用户名的校验方法
    let validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入用户名"));
      }
      // 用户名以字母开头,长度在5-16之间,允许字母数字下划线
      // const userNameRule = /^[a-zA-Z][a-zA-Z0-9_]{4,15}$/;
      // if (userNameRule.test(value)) {
        this.$refs.ruleForm.validateField("checkPass");
        return callback();
      // } else {
      //   return callback(new Error("字母开头,长度5-16之间,允许字母数字下划线"));
      // }
    };
    // 密码的校验方法
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入密码"));
      }
      // 密码以字母开头,长度在6-18之间,允许字母数字和下划线
      //const passwordRule = /^[a-zA-Z]\w{5,17}$/;
      //if (passwordRule.test(value)) {
        this.$refs.ruleForm.validateField("checkPass");
        return callback();
      //} else {
      //  return callback(
      //    new Error("字母开头,长度6-18之间,允许字母数字和下划线")
      //  );
      //}
    };
    return {
      LoginUser: {
        account: '',
        password: '',
        code: ''
      },
      // 用户信息校验规则,validator(校验方法),trigger(触发方式),blur为在组件 Input 失去焦点时触发
      rules: {
        account: [{ validator: validateName, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }]
      },
      checked: false,
      loginType: 2,
    }
  },
  methods: {
    ...mapActions(["setUser", "setShowLogin"]),
    jumpall(){
      this.$router.push('/user/loginAll')
    },
    Login() {
      // 通过element自定义表单校验规则，校验用户输入的用户信息
      this.$refs["ruleForm"].validate(valid => {
        if (!this.checked) {
          this.$message.error('请阅读并同意用户协议和隐私政策！');
        }else 
        //如果通过校验开始登录
        if (valid) {
          loginApi(this.LoginUser).then(res => {
            // 登录信息存到本地
            let userinfo = JSON.stringify(res.data.userinfo);
            localStorage.setItem("token",res.data.userinfo.token)
            localStorage.setItem("user", userinfo);
            // 登录信息存到vuex
            this.setUser(res.data.userinfo);
            // 弹出通知框提示登录成功信息
            this.$message.success(res.msg);
            this.$router.push('/')
          })
          .catch((err) => {
            // 清空输入框的校验状态
            // this.$refs["ruleForm"].resetFields();
            this.$message.error(err.data.msg);
            return Promise.reject(err);
          });
        } else {
          return false;
        }
      });
    },
    // 跳转注册
    jumpReg(){
      this.$router.push('/user/register')
    },
    togLoginType(type){
      this.loginType = type
    },
  }
};
</script>
<style>
  .loginform .el-form-item__content{
  margin-left: 0 !important;
}
.loginform .el-input__inner{
  height: 48px !important;
  font-size: 21px !important;
}
</style>
<style  scoped>
  .code{
    cursor: pointer;
    font-size: 21px;
  }
  .btn-box-b{
    display: flex;
    justify-content: flex-end;
  }
  .qita{
    margin-right: 20px;
    font-size: 20px;
    text-align: center;
    cursor: pointer;
  }
.login{
  padding-top: 100px;
  width: 100%;
  height: 700px;
  background-image: url('../../assets/index/bglogin.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top left;

}
.tog-log{
  display: flex;
  
}
.log-box{
  padding: 30px;
  margin: 0 auto 0;
  width: 403px;
  /* height: 379px; */
  background: #FFFFFF;
  border-radius: 26px 26px 26px 26px;;
}
.log-tit{
  position: relative;
  /* width: 105px; */
  height: 36px;
  font-family: Alibaba PuHuiTi, Alibaba PuHuiTi;
  font-weight: 500;
  font-size: 20px;
  color: #070707;
  line-height: 38px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  cursor: pointer;
}
.active::after{
  content: '';
  display: block;
  position: absolute;
  bottom: -24px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  width: 86px;
  height: 8px;
  background: #CE3720;
  border-radius: 3px 3px 3px 3px;
}
.btn{
  margin: 0 auto;
  height: 46px;
  background: #CE3720;
  border-radius: 5px 5px 5px 5px;
  text-align: center;
  line-height: 46px;
  font-weight: 400;
  font-size: 24px;
  color: #FFFFFF;
  letter-spacing: 6px;
  cursor: pointer;
}
.xiyi{
  font-size: 19px;
  margin: 20px 0;
}
.reg-btn{
  text-align: right;
  color: #CE3720;
  cursor: pointer;
  font-size: 20px;
}
</style>
